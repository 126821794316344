import React from "react"
import WebpImage from "../WebpImage"
import Banner from "../Banner"

import "./style.scss"
import useIsLifemart from '../Lifemart/hooks/useIsLifemart';
import ProductTag from "../ProductTag";

const imagesByColor = {
  orange: "assets/GlassesHeading/Hubble-Glasses-PCP-Hero-Desktop.jpg",
  blue: "assets/GlassesHeading/Hubble-Accessories-PCP-Hero-Desktop.jpg",
  green: "assets/GlassesHeading/Hubble-Sunlasses_PCP_Hero_Desktop.jpg"
}

const GlassesHeading = ({ color, title, description }) => {
  const isGlasses = title.includes("Eyeglasses") || title.includes("Glasses")
  const isSunglasses = title.includes("Sunglasses")
  const isLifemart = useIsLifemart()

  return (
    <div className="glasses-heading-container" data-color={color}>
      {
        isLifemart && (isGlasses || isSunglasses) &&
          <Banner
            bannerClass={`promo-banner affiliate-banner`}
            bannerText={`Get <b>25% off all frames</b> in partnership with LifeMart`}
            bannerBG={color}
          />
      }
      
      <div className="glasses-heading--background">
        <WebpImage fileName={imagesByColor[color]} />
      </div>
      <div className="grid-section">
        {(isGlasses || isSunglasses) && <p className={`subheading ${color}`}>NOW STARTING AT ${isGlasses && 49.99 || isSunglasses && 59.99}</p>}
        <h1 className="text h2 bulky-wide heading">{title}</h1>
        <p className="description text neue" data-type={isGlasses ? "glasses" : isSunglasses ? "sunglasses" : ""}>{description}</p>
        {(isGlasses || isSunglasses) && <ProductTag label text="New reduced price" color={color} />}
      </div>
    </div>
  )
}

export default GlassesHeading
